import React from "react";
import { LandingPageTemplate } from "../ui/templates/LandingPageTemplate";
import { SeoShareQuiz } from "../components/SeoShareQuiz";

const ShareQuizPage = () => {
  return(
      [
          <SeoShareQuiz />,
          <LandingPageTemplate />
      ]
  );
};

export default ShareQuizPage;
